@import "lib/bootstrap/scss/bootstrap.scss";

body {
  background-color: #e9ecef;
}

.tab-pane {
  background-color: #fff;
  padding-top: 5em;
  padding-bottom: 5em;
  padding-left: 3em;
  padding-right: 3em;
  border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-right: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
}

// fix border-radius for tab pills (see _nav.scss)
.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-link {
    border: $nav-tabs-border-width solid transparent;
    @include border-top-radius($nav-tabs-border-radius);
    @include border-bottom-radius(0);
  }
}

.form-check {
  margin-bottom: 0.25rem;
}

// entire mixin overridden here so we can set a fixed color on hover state
@mixin list-group-item-variant($state, $background, $color) {
  .list-group-item-#{$state} {
    color: $color;
    background-color: $background;

    &.list-group-item-action {
      @include hover-focus() {
      /*
        color: $color;
        background-color: darken($background, 5%);
      */
        color: $white;
        background-color: #818182;
      }

      &.active {
        color: $white;
        background-color: $color;
        border-color: $color;
      }
    }
  }
}

$inactive-article-color: #818182;
$inactive-article-color-background: $gray-100 !default;

@include list-group-item-variant("light", theme-color-level("light", -9), $list-group-action-color);
@include list-group-item-variant("inactive-article", $inactive-article-color-background, $inactive-article-color);

em {
 // bg-warning text-dark
 background-color: theme-color("warning") !important;
 color: theme-color("dark") !important;
 font-style: normal;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.thumb-wrapper {
    .img-overlay {
        //display: none;

        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 3px;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-direction: column;
        background: transparent;
        transition: background 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, border-color 0.3s ease 0s;
        //padding: 16px;

        z-index: 2;
    }

    .img-overlay:hover {
        background-color: rgba(9, 30, 66, 0.5);
    }

    img:hover + .img-overlay, .img-overlay:hover {
        display: inline-block;
    }


    .img-overlay .top-row {
        transition: opacity 0.3s ease 0s;
        opacity: 0;
        color: white;
        visibility: hidden;
    }
    .img-overlay:hover .top-row {
        opacity: 1;
        visibility: visible;
    }

    .img-overlay .bottom-row {
        opacity: 0;
        transition: transform 0.2s ease 0s, opacity 0.5s ease 0s;
        transform: translateY(35px);
    }
    .img-overlay:hover .bottom-row {
        opacity: 1;
        transform: translateY(0px);
    }
}

.img-container {
    height: 400px;
    overflow:hidden;
}

@include media-breakpoint-up(md) {
    .tab-content {
        min-height: 37.5em;
    }
}
